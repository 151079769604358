*{
  box-sizing: border-box;
}

html, body, #root, .App{
  width: 100%;
  height: 100%;
  padding:0;
  margin:0;
  position: relative;
}
body{
  background: linear-gradient(180deg,#1F1831 0%,#2D214D 100%);
}
a{
  outline: none;
  text-decoration: none;
}

#chartdiv > div > svg > g > g:nth-child(2) > g:nth-child(2) > g > g:nth-child(3){
  display: none;
}
.claim-container{
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 150px 50px 30px;
}
.page-title-block{
  flex-basis: 80%;
}
.btn-block{
  flex-basis: 80%;
}
.side-block{
  flex-basis: 35%;
  margin-top:  20px;
}


@media screen and  (min-width: 1200px) {
  
}
@media screen and (max-width: 900px) {
  .main-block{
    flex-basis: 50%;
    width: 50%;
  }
  .side-block{
    flex-basis: 45%;
  }
}
@media screen and (max-width: 768px) {
  .claim-container{
    padding: 80px 30px 30px;
  }
  .main-block{
    flex-basis: 100%;
  }
  .side-block{
    flex-basis: 100%;
  }
}
@media screen and (max-width: 520px) {
  .your-address{
    font-size: 14px;
    display: block;
    margin-bottom:10px
  }
  .wallet-address{
    font-size: 14px;
  }
}